<template>
  <div>
    <navbar-top left="back"
                middle="logo"
                right="logout"
                @cl="back"
                @cr="logout" />

    <div class="fixed inset-0 pt-12 bg-blue-850">
      <div v-if="dataLoaded"
           class="wrapper p-6 text-center">
        <div v-if="cl">
          <div class="p-4 text-white rounded bg-blue-900">
            <p class="text-lg font-semibold">{{ cl.classData.ownerInfo.name }} {{ cl.classData.ownerInfo.surname }}</p>
            <p>приглашает вас в класс</p>
            <p class="py-4 text-3xl font-semibold">"{{ cl.classData.name }}"</p>
            <button v-if="!joinedSuccessfully"
                    class="mt-3 p-2 rounded bg-green-600 font-bold text-white"
                    @click="joinClass">Присоединиться</button>
            <button v-else
                    class="mt-3 p-2 rounded bg-green-600 font-bold text-white"
                    disabled>
              <font-awesome-icon icon="check"
                                 class="text-white text-xl"></font-awesome-icon>
            </button>
          </div>
        </div>
        <div v-else>
          <p class="text-white">По этой ссылке нет приглашений. Возможно, срок ее действия истек, а возможно, она указана с ошибкой.
            Убедись что ссылка набрана правильно, и попробуй перейти по ней снова.</p>
          <button class="mt-4 p-2 rounded bg-blue-600 font-semibold text-white"
                  @click="back">Вернуться в меню</button>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/services/axios';
import NavbarTop from "@/components/NavbarTop";
import MenuSpinner from "@/components/MenuSpinner";

export default {
  components: { NavbarTop, MenuSpinner },
  props: {
    invite: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      dataLoaded: false,
      cl: undefined,
      joinedSuccessfully: false,
    };
  },
  created () {
    const url = `/api/v1/invites/${this.invite}`;
    return axiosInstance.get(url)
        .then((res) => {
          this.cl = res.data[0];
          this.dataLoaded = true;
        }).catch(() => { this.dataLoaded = true; });
  },
  methods: {
    joinClass () {
      const url = `/api/v1/class/join/${this.cl.class}`;
      return axiosInstance.patch(url)
          .then(() => {
            localStorage.removeItem('invite');
            this.joinedSuccessfully = true;
            setTimeout(() => {
              console.log('timeout!');
              this.$router.push('/');
            }, 2000);
          });
    },
    logout () {
      this.dataLoaded = false;
      return this.$store.dispatch('auth/logout')
        .then(() => {
          if (navigator.serviceWorker.controller)
            navigator.serviceWorker.controller.postMessage('clearAuth');
          this.$router.push('/login');
        });
    },
    back () {
      localStorage.removeItem('invite');
      return this.$router.push('/');
    },
  },
}
</script>

<style>

</style>
